import { Component, Injector } from '@angular/core';
import { unauthorizedErrMsg } from '@app/core/models/ui.model';
import { NeoBaseComponent } from '@app/shared/components/base/neo-base.component';

@Component({
    selector: 'neo-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: false
})
export class ErrorComponent extends NeoBaseComponent {
  public errMsg = unauthorizedErrMsg

  constructor(protected injector: Injector) {
    super(injector);
  }
}
